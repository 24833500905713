// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import MicroModal from "micromodal";
import { Controller } from "stimulus"
import '../stylesheets/stripe.scss';
export default class extends Controller {
  static targets = ['amount', 'error', 'credits', 'preview', 'creditspreview', 'billpreview', 'subscribebillpreview']
  static values = { credit: Number, publickey: String };
  connect() {
    this.previewAmount();
    // A reference to Stripe.js initialized with a fake API key.
    // Sign in to see examples pre-filled with your key.
  }

  addBalance() {
    let balance = parseInt(this.creditsTarget.value || 0);
    if (balance >= 5) {
      this.errorTarget.innerText = "";
      MicroModal.show("modal-1");
      this.startPayment(this.creditsTarget.value * this.creditValue);
    } else {
      this.errorTarget.innerText = "Please enter 5 or more credits";
    }
  }

  previewAmount(e) {
    let balance = parseInt(this.creditsTarget.value || 0);
    if (balance != NaN) {
      this.previewTarget.innerText = balance * this.creditValue;
      this.billpreviewTarget.innerText = balance * this.creditValue;
      this.creditspreviewTarget.innerText = this.creditsTarget.value;
    } else {
      this.previewTarget.innerText = 0;
    }
  }

  startPayment(amount) {
    var stripe = Stripe(this.publickeyValue);
    // The items the customer wants to buy
    var purchase = {
      amount: amount,
      credits: this.creditsTarget.value,
    };
    // Disable the button until we have Stripe set up on the page
    document.querySelector("button").disabled = true;
    fetch("/create-payment-intent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(purchase)
    })
      .then(function (result) {
        return result.json();
      })
      .then(function (data) {
        var elements = stripe.elements();
        var style = {
          base: {
            color: "#32325d",
            fontFamily: 'Arial, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
              color: "#32325d"
            }
          },
          invalid: {
            fontFamily: 'Arial, sans-serif',
            color: "#fa755a",
            iconColor: "#fa755a"
          }
        };

        var card = elements.create("card", { style: style });
        // Stripe injects an iframe into the DOM
        card.mount("#card-element");
        card.on("change", function (event) {
          // Disable the Pay button if there are no card details in the Element
          document.querySelector("button").disabled = event.empty;
          document.querySelector("#card-error").textContent = event.error ? event.error.message : "";
        });
        var form = document.getElementById("payment-form");
        form.style.display = 'block';
        form.addEventListener("submit", function (event) {
          event.preventDefault();
          // Complete payment when the submit button is clicked
          payWithCard(stripe, card, data.clientSecret);
        });
      });
    // Calls stripe.confirmCardPayment
    // If the card requires authentication Stripe shows a pop-up modal to
    // prompt the user to enter authentication details without leaving your page.
    var payWithCard = function (stripe, card, clientSecret) {
      loading(true);
      stripe
        .confirmCardPayment(clientSecret, {
          payment_method: {
            card: card
          }
        })
        .then(function (result) {
          if (result.error) {
            // Show error to your customer
            showError(result.error.message);
          } else {
            // The payment succeeded!
            console.log(result);
            var formData = new FormData();
            formData.append('id', result.paymentIntent.id);
            Rails.ajax({
              type: "POST",
              url: '/payment-success',
              data: formData,
            })
            orderComplete(result.paymentIntent.id);
          }
        });
    };
    /* ------- UI helpers ------- */
    // Shows a success message when the payment is complete
    var orderComplete = function (paymentIntentId) {
      loading(false);
      document.querySelector(".result-message").classList.remove("hidden");
      document.querySelector("button").disabled = true;
      setTimeout(function () { location.reload(); }, 5000);

    };
    // Show the customer the error from Stripe if their card fails to charge
    var showError = function (errorMsgText) {
      loading(false);
      var errorMsg = document.querySelector("#card-error");
      errorMsg.textContent = errorMsgText;
      setTimeout(function () {
        errorMsg.textContent = "";
      }, 4000);
    };
    // Show a spinner on payment submission
    var loading = function (isLoading) {
      if (isLoading) {
        // Disable the button and show a spinner
        document.querySelector("button").disabled = true;
        document.querySelector("#spinner").classList.remove("hidden");
        document.querySelector("#button-text").classList.add("hidden");
      } else {
        document.querySelector("button").disabled = false;
        document.querySelector("#spinner").classList.add("hidden");
        document.querySelector("#button-text").classList.remove("hidden");
      }
    };

  }

  subscribe() {
    var stripe = Stripe(this.publickeyValue);
    var that = this;
    fetch("/create-subscription-intent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
    })
      .then(function (result) {
        return result.json();
      })
      .then(function (data) {
        const options = {
          clientSecret: data.clientSecret,
          // Fully customizable with appearance API.
          appearance: {/*...*/ },
        };
        that.subscribebillpreviewTarget.innerText = data.amount;
        const elements = stripe.elements(options);
        const paymentElement = elements.create('payment');
        paymentElement.mount('#payment-element-2');
        const form = document.getElementById('payment-form-2');

        form.addEventListener('submit', async (event) => {
          console.log("submit")
          event.preventDefault();

          const { error } = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
              return_url: `${window.location.origin}/payment-success?subscription_id=${data.subscriptionId}`,
            }
          });

          if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            const messageContainer = document.querySelector('#error-message');
            messageContainer.textContent = error.message;
          } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
          }
        });
      });


  }
}
