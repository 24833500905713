// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import MicroModal from 'micromodal';  // es6 module
import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';

export default class extends Controller {

  static targets = ["shareContainer","form","copy","pin","email","unlock", "step1", "step2", "step3", "step4","demographics","shareState", "error", "navigation", "emailContainer", "pinContainer", "success", "billing","resetid",'phoneContainer','phone','spinner', 'pid', 'firstname', 'lastname','middlename', 'sex','examdate','studydesc', 'demographicsForm', 'dob']
  iti;

  connect() {
  }

  copy() {
    var copyText = this.copyTarget;
    copyText.select();
    copyText.setSelectionRange(0, 99999)
    document.execCommand("copy");
  }

  shareStudy(e) {
    const that = this;
    let url = e.target.getAttribute("data-share-url");
    Rails.ajax({
      type: "GET",
      url: url,
      success: function(data) {
        that.shareContainerTarget.innerHTML = data.html;
      }
    })
  }

  deleteExam(e) {
    this.demographicsFormTarget.classList.add('hidden');
  }

  editExam(e) {
    this.demographicsFormTarget.classList.remove('hidden');
  }

  share() {
  const that = this;
  const event = new CustomEvent("shareUpdate", {});
  if(this.hasUnlockTarget) {
      let study_id = this.unlockTarget.getAttribute('data-study-id')
      let url = `/${this.data.get('model')}/${study_id}/unlock`;
      Rails.ajax({
        type: "GET",
        url: url,
        data: formData,
        success:  function(data) {
          MicroModal.close('modal-1');
        }
      })
    } else {
    let share_status = this.shareStateTarget.value;
    let share_item = document.querySelector('input[name="shareType"]:checked');
    let security = document.querySelector('input[name="security"]:checked');
    let isValid = false
    let study_id = share_item.getAttribute("data-study-id");
    let share_type = share_item.value;
    let url = `/${that.data.get('model')}/${study_id}/share_details`;
    let lararad_share_url = `/${that.data.get('model')}/${study_id}/lararad_share`;
    let demographics_share_url = `/${that.data.get('model')}/${study_id}/demographics_share`;
    let radiologist_share_url = `/${that.data.get('model')}/${study_id}/radiologist_share`;

    if(share_status == 'step1' && share_item.value == 'lararad') {
      var formData = new FormData();
      formData.append('share_type', share_type);
      Rails.ajax({
        type: "POST",
        url: lararad_share_url,
        data: formData,
        beforeSend: function(e) {
          that.navigationTarget.classList.add('hidden');
          that.step1Target.innerHTML = 'Loading...';
          return true;
        },
        success:  function(data) {
          if(data.status == 'success') {
            window.location = data.lararad_url;
            // window.open(data.lararad_url, 'lararad');
            MicroModal.close('modal-1');
          } else {
            that.navigationTarget.classList.add('hidden');
            if(data.reseller == false) {
              that.billingTarget.classList.remove('hidden');
            }
            that.shareContainerTarget.innerHTML = data.html;
          }

        }
      })
    } else if(share_status == 'step1' && share_item.value == 'demographics') {
        that.step1Target.classList.add('hidden');
        that.shareStateTarget.value = 'demographics';
        that.demographicsTarget.classList.remove('hidden');
    } else if(share_status == 'demographics' || share_status == 'import') {
        var formData = new FormData();
      formData.append('share_type', share_type);
      formData.append('pid', that.pidTarget.value);
      formData.append('lastname', that.lastnameTarget.value)
      formData.append('firstname', that.firstnameTarget.value)
      formData.append('middlename', that.middlenameTarget.value)
      formData.append('examdate', that.examdateTarget.value)
      formData.append('sex', that.sexTarget.value)
      formData.append('studydesc', that.studydescTarget.value)
      formData.append('dob', that.dobTarget.value)
      console.log(that.examdateTarget.value);
      formData.append('edit_type', [...document.getElementsByName("edit_type")].find(input => input.checked).value);
      Rails.ajax({
        type: "POST",
        url: demographics_share_url,
        data: formData,
        beforeSend: function() {
          that.navigationTarget.classList.add('hidden');
          that.demographicsTarget.innerHTML = 'Loading...';
          return true;
        },
        success:  function(data) {
        that.demographicsTarget.classList.add('hidden');
        that.step4Target.classList.remove('hidden');
        that.shareStateTarget.value = 'step4';
          that.shareContainerTarget.innerHTML = data.html;
          if(that.hasSuccessTarget) {
            that.successTarget.innerText = data.message;
          }
          if(data.status == 'success'){
            that.navigationTarget.classList.add('hidden');
          } else {
            that.navigationTarget.classList.add('hidden');
            if(data.reseller == false) {
              that.billingTarget.classList.remove('hidden');
            }
          }
        }
      })
    }else if(share_status == 'step1' && share_item.value == 'radiologist') {
      var formData = new FormData();
      formData.append('share_type', share_type);
      Rails.ajax({
        type: "POST",
        url: radiologist_share_url,
        data: formData,
        beforeSend: function(e) {
          that.navigationTarget.classList.add('hidden');
          that.step1Target.innerHTML = 'Loading...';
          return true;
        },
        success:  function(data) {
        that.step1Target.classList.add('hidden');
        that.step4Target.classList.remove('hidden');
        that.shareStateTarget.value = 'step4';
          that.shareContainerTarget.innerHTML = data.html;
          if(that.hasSuccessTarget) {
            that.successTarget.innerText = data.message;
          }
          if(data.status == 'success'){
            that.navigationTarget.classList.add('hidden');
          } else {
            that.navigationTarget.classList.add('hidden');
            if(data.reseller == false) {
              that.billingTarget.classList.remove('hidden');
            }
          }
        }
      })
    }else if(share_status == 'step1' && share_item.value == 'import') {
      that.step1Target.classList.add('hidden');
      that.shareStateTarget.value = 'demographics';
      that.demographicsTarget.classList.remove('hidden');
    }
    else if(share_status == 'step1' && share_item) {
      this.step1Target.classList.add('hidden');
      this.step2Target.classList.remove('hidden');
      this.shareStateTarget.value = 'step2';
      this.errorTarget.innerText = "";
    } else if(share_status == 'step1' && share_item == undefined) {
      this.errorTarget.innerText = "Please choose share type"
    } else if(share_status == 'step2' && security) {
      this.step2Target.classList.add('hidden');
      if(share_item.value == 'email' || security.value == 'pin' || share_item.value == 'sms') {
        this.phoneInit();
        this.step3Target.classList.remove('hidden');
        this.shareStateTarget.value = 'step3';
        this.pinTarget.focus();
        this.emailTarget.focus();
      } else {
        this.step4Target.classList.remove('hidden')
        this.shareStateTarget.value = 'step4';
      }
      if(share_item.value == 'email') {
        this.emailContainerTarget.classList.remove('hidden');
        this.emailTarget.focus();
      }
      if(share_item.value == 'sms') {
        this.phoneContainerTarget.classList.remove('hidden');
        this.phoneTarget.focus();
      }
      if(security.value == 'pin') {
        this.pinContainerTarget.classList.remove('hidden');
        this.pinTarget.focus();
      }
      this.errorTarget.innerText = "";
    } else if(share_status == 'step2' && security == undefined) {
      this.errorTarget.innerText = "Please choose security"
    } else if(share_status == 'step3' && share_item.value == 'email' && this.emailTarget.value ) {
      this.step3Target.classList.add('hidden');
      this.shareStateTarget.value = 'step4';
      this.step4Target.classList.add('hidden');
      this.errorTarget.innerText = "";
    } else if(share_status == 'step3' && share_item.value == 'email' && this.emailTarget.value == "") {
      this.emailTarget.autofocus;
      this.errorTarget.innerText = "Please Enter Email"
    } else if(share_status == 'step3' && share_item.value == 'sms' && !this.iti.isValidNumber()) {
      console.log(this.iti);
      this.phoneTarget.autofocus;
      this.errorTarget.innerText = "Please Enter Valid Phone number"
    }
     else if(share_status == 'step3') {
      this.step3Target.classList.add('hidden');
      this.shareStateTarget.value = 'step4';
      this.step4Target.classList.add('hidden');
      this.errorTarget.innerText = "";
    }
    let security_value = security ? security.value : "pin";
    let reset_uuid = this.hasResetidTarget ? this.resetidTarget.checked : false;
    if(share_item && security && this.shareStateTarget.value== 'step4') {

      var formData = new FormData();
      formData.append('share_type', share_type);
      formData.append('email', this.emailTarget.value);
      formData.append('pin', this.pinTarget.value);
      formData.append("security", security_value);
      formData.append("reset_uuid", reset_uuid);
      if(share_type == 'sms') {
        formData.append("phone_number", this.iti.getNumber());
      }
      // const div = document.createElement("DIV");
      // let spinner = that.spinnerTarget.cloneNode(true);
      // div.classList.add("relative", "justify-center","flex");
      // div.style.cssText = 'width:300px; height:300px';
      // div.appendChild(spinner);
      that.shareContainerTarget.innerHTML = 'Loading...';
      Rails.ajax({
        type: "POST",
        url: url,
        data: formData,
        success:  function(data) {
          that.shareContainerTarget.innerHTML = data.html;
          if(that.hasSuccessTarget) {
            that.successTarget.innerText = data.message;
          }
          if(data.status == 'success'){
            that.navigationTarget.classList.add('hidden');
          } else {
            that.navigationTarget.classList.add('hidden');
            if(data.reseller == false) {
              that.billingTarget.classList.remove('hidden');
            }
          }
        }
      })
    }

    }
  }

  phoneInit() {
    const that = this;
    var input = document.querySelector("#phone"),
      errorMsg = document.querySelector("#error-msg");
      // here, the index maps to the error code returned from getValidationError - see readme
      var errorMap = ["Invalid number", "Invalid country code", "Too short", "Too long", "Invalid number"];

      // initialise plugin
      this.iti = intlTelInput(input, {
        utilsScript: "../../build/js/utils.js?1638200991544"
      });

      var reset = function() {
        input.classList.remove("error");
        errorMsg.innerHTML = "";
        errorMsg.classList.add("hide");
      };

      // on blur: validate
      input.addEventListener('blur', function() {
        reset();
        if (input.value.trim()) {
          if (that.iti.isValidNumber()) {
          } else {
            input.classList.add("error");
            var errorCode = that.iti.getValidationError();
            errorMsg.innerHTML = errorMap[errorCode];
            errorMsg.classList.remove("hide");
          }
        }
      });

      // on keyup / change flag: reset
      input.addEventListener('change', reset);
      input.addEventListener('keyup', reset);

  }
}
