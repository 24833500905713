/* eslint-disable class-methods-use-this */
/* eslint-disable no-undef */
import { Controller } from 'stimulus';
import fileUpload from '../custom/upload/upload';

export default class extends Controller {
  static targets = ['formContent', 'unlockContent', 'submissionUrl'];

  connect() {
    document.querySelectorAll('.drag-drop-area').forEach((element) => {
      fileUpload(element);
    });
  }
}
