import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';

import { Grid, simpleHttpRequest } from 'ag-grid-community';

import { Controller } from "stimulus"
import ModalController from "./modal_controller";
export default class AggridController extends Controller {
  static targets = ["grid", "shareContainer", "billing", "navigation", "close"]
  loading = false;
  connect() {
    if (this.hasGridTarget) {
      this.load();
    } else {
      let modal = new ModalController();
      modal.connect();
    }
    const that = this;
    document.addEventListener("shareUpdate", function (e) {
      if (that.loading == false) {
        that.load();
      }
    });

  }

  load() {
    this.loading = true;
    let url = this.data.get('url');
    const that = this;
    var columnDefs = [
      { headerName: "Actions", field: "custom", cellRenderer: actionIcons, lockPinned: true, pinned: 'left', suppressSizeToFit: true, minWidth: this.data.get('model') == 'exams' ? 265 : 300, width: this.data.get('model') == 'exams' ? 265 : 300 },
      { headerName: "Patient ID", field: "PID", filter: 'agTextColumnFilter', width: 150, minWidth: 150 },
      { headerName: "Patient Name", field: "PName", filter: 'agTextColumnFilter', width: 180, minWidth: 180 },
      { headerName: "Modality", field: "Modality", filter: 'agTextColumnFilter', width: 65, maxWidth: 100, minWidth: 65 },
      { headerName: "Images", field: "NumOfImages", width: 65, maxWidth: 100, minWidth: 65 },
      { headerName: "Ref Physician Name", field: "RefPhysicianName", filter: 'agTextColumnFilter', width: 150, minWidth: 150 },
      { headerName: "Description", field: "StudyDesc", filter: 'agTextColumnFilter', width: 128, minWidth: 128 },
      { headerName: "Study Exam DateTime", field: "StudyDate", filter: 'agTextColumnFilter', sortable: true, width: 165, maxWidth: 250, comparator: dateComparator, minWidth: 165 },
      { headerName: "Sex", field: "PSex", filter: 'agTextColumnFilter', width: 82, maxWidth: 100, minWidth: 82 },
      { headerName: "DOB", field: "PBirthDateTime", filter: 'agTextColumnFilter', width: 110, maxWidth: 150, minWidth: 110 },
      { headerName: "Institution Name", field: "InstitutionName", filter: 'agTextColumnFilter', width: 180, minWidth: 180 },
    ];

    // let the grid know which columns to use
    var gridOptions = {
      columnDefs: columnDefs,
      onFirstDataRendered: firstDataRendered,
      onColumnResized: (event) => {
        window.localStorage.setItem('colSize', JSON.stringify(gridOptions.columnApi.getColumnState()));
      },
      onDragStopped: (event) => {
        window.localStorage.setItem('colSize', JSON.stringify(gridOptions.columnApi.getColumnState()));
      },
      floatingFilter: this.data.get('model') == 'exams' ? false : true,
      filter: true,
      pagination: true,
      paginationPageSize: 12,
      onPaginationChanged: onPaginationChanged,
      overlayNoRowsTemplate: `<span>${this.data.get('norows') || 'No Rows Found'}</span>`,
      defaultColDef: {
        resizable: true,
      },
    };
    this.gridTarget.innerHTML = '';

    // lookup the container we want the Grid to use
    // create the grid passing in the div to use together with the columns & data we want to use
    const agGrid = new Grid(this.gridTarget, gridOptions);
    simpleHttpRequest({ url: url }).then(function (data) {
      gridOptions.api.setRowData(data);
      that.loading = false;
    });
    // document.addEventListener("sideBarToggle", function(){
    //   gridOptions.api.sizeColumnsToFit()
    // });
  }

  shareStudy(e) {
    // if(e.currentTarget.innerText == 'SHARED') {
    //     this.navigationTarget.classList.add("hidden");
    //     this.closeTarget.classList.add("hidden");
    // } else {
    //   this.navigationTarget.classList.remove("hidden");
    //   this.closeTarget.classList.remove("hidden");

    // }
    this.navigationTarget.classList.remove("hidden");
    this.billingTarget.classList.add("hidden")
    this.shareContainerTarget.innerHTML = 'Loading...'
    let url = `/${this.data.get('model')}/${e.currentTarget.getAttribute('data-study-id')}/share`;
    const that = this;
    Rails.ajax({
      type: "GET",
      url: url,
      success: function (data) {
        that.shareContainerTarget.innerHTML = data.html;
      }
    })
  }
}

function actionIcons() { }
actionIcons.prototype.init = function (params) {
  const tempDiv = document.createElement('div');
  let bgColor;
  if (params.data.shared_status == 'EXPIRED') {
    bgColor = 'bg-yellow'
  } else if (params.data.shared_status == 'SHARED') {
    bgColor = 'bg-green'
  } else if (params.data.shared_status == 'LOCKED' || params.data.shared_status == 'DISABLED') {
    bgColor = 'bg-red';
  } else {
    bgColor = 'bg-indigo'
  }
  let study_key = (params.data.modal == 'exams') ? params.data.StudyInstanceUID : params.data.id;

  tempDiv.innerHTML = `<button title="Preview" class="px-2 py-1 mx-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-indigo-600 border border-transparent rounded-lg active:bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:shadow-outline-purple modal-open" data-action="click->preview#showPreview" data-micromodal-trigger="modal-2" data-study-id="${study_key}" data-modal="${params.data.modal}">Preview</button>`

  if (params.data.shared_status != 'shared') {
    tempDiv.insertAdjacentHTML('beforeend', `<button title="Share" class="px-2 py-1 text-sm font-medium leading-5 text-white transition-colors duration-150 ${bgColor}-600 border border-transparent rounded-lg active:${bgColor}-600 hover:${bgColor}-700 focus:outline-none focus:shadow-outline-purple modal-open" data-action="click->aggrid#shareStudy" data-micromodal-trigger="modal-1" data-study-id="${study_key}">${params.data.shared_status}</button>`);
  }

  if (params.data.amplify_pacs == true) {
    let shareButton = `<a title="View" class="px-2 py-1 text-sm font-medium leading-5 mx-1 text-white transition-colors duration-150 bg-indigo-600 border border-transparent rounded-lg active:bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:shadow-outline-purple modal-open" href="${params.data.share_url}" target="_blank">View</a>`
    tempDiv.insertAdjacentHTML('afterbegin', shareButton);
  }
  if (params.data.id && params.data.shared_status != 'SHARED') {
    // tempDiv.insertAdjacentHTML('beforeend', `<a title="Delete" class="mx-1 px-2 py-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg active:bg-red-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-purple" href="/custom_studies/${params.data.id}" data-method="delete" data-confirm="Are you sure?"><i class="fa fa-trash"></i></a>`);

  }
  if (params.data.id && params.data.shared_status == 'SHARED') {
    // tempDiv.insertAdjacentHTML('beforeend', `<a title="Delete" class="mx-1 px-2 py-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg active:bg-red-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-purple" href="/custom_studies/${params.data.id}" data-method="delete" data-confirm="Warning! if you delete this exam, the images will no longer be visible or accessible to any party, including LaraRad Radiologists and you will lose the original credit spent to share the exam."><i class="fa fa-trash"></i></a>`);
  }
  if (params.data.shared_status == 'SHARED' && params.data.modal != 'shared_studies') {
    tempDiv.insertAdjacentHTML('beforeend', `<a title="Disable" class="px-2 py-1 mx-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg active:bg-red-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-purple" href="/${params.data.modal}/${study_key}/revoke" data-confirm="Are you sure?"><i class="fa fa-close"></i></a>`);
  }
  this.eGui = tempDiv;
};

actionIcons.prototype.getGui = function () {
  return this.eGui;
};


function firstDataRendered(e) {
  // e.columnApi.autoSizeColumns(['NumOfSeries','PSex','custom','PBirthDateTime'],true);

  // e.api.sizeColumnsToFit();
  setTimeout(function () {
    let modal = new ModalController();
    modal.connect();
  }, 1000);
  if (!window.localStorage.getItem('colSize')) {
    console.log('no columns state to restore by, you must save state first');
    return;
  } else {
    e.columnApi.applyColumnState({
      state: JSON.parse(window.localStorage.getItem('colSize')),
      applyOrder: true,
    });
  }

}

function onPaginationChanged(e) {
  setTimeout(function () {
    let modal = new ModalController();
    modal.connect();
  }, 1000);
}


function dateComparator(date1, date2) {
  var date1Number = monthToComparableNumber(date1);
  var date2Number = monthToComparableNumber(date2);

  if (date1Number === null && date2Number === null) {
    return 0;
  }
  if (date1Number === null) {
    return -1;
  }
  if (date2Number === null) {
    return 1;
  }

  return date1Number - date2Number;
}

// eg 29/08/2004 gets converted to 20040829
function monthToComparableNumber(date) {
  if (date === undefined || date === null || date.length !== 19) {
    return null;
  }

  var yearNumber = date.substring(6, 10);
  var dayNumber = date.substring(3, 5);
  var monthNumber = date.substring(0, 2);
  var hourNumber = date.substring(11, 13);
  var minuteNumber = date.substring(14, 16);
  var secondNumber = date.substring(17, 19);
  var result = `${yearNumber}${monthNumber}${dayNumber}${hourNumber}${minuteNumber}${secondNumber}`;
  return parseInt(result);
}

class CustomNoRowsOverlay {
  init(params) {
    this.eGui = document.createElement('div');
    this.eGui.innerHTML = `
           <div class="ag-overlay-loading-center" style="background-color: lightcoral;">
               <i class="far fa-frown"> This is testing </i>
           </div>
       `;
  }

  getGui() {
    return this.eGui;
  }
}
