import 'uppy/dist/uppy.min.css';

import {
  Core,
  FileInput,
  Informer,
  ProgressBar,
  ThumbnailGenerator,
  Dashboard,
  XHRUpload,
  StatusBar,
  DragDrop,
} from 'uppy';

const dicomParser = require('dicom-parser');

function fileUpload(fileInput) {
  const inputParent = fileInput.parentElement;

  // const progressTarget = inputParent.querySelector('.for-ProgressBar');
  const url = fileInput.getAttribute('data-url');
  const redirect_url = fileInput.getAttribute('data-redirect-url');
  // const formGroup = fileInput.parentNode;

  // remove our file input in favour of Uppy's
  // formGroup.removeChild(fileInput);

  const uppy = Core({
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 700,
    },

  })
    .use(Dashboard, {
      target: fileInput,
      inline: true,
      replaceTargetContent: true,
      showProgressDetails: true,
      width: '80vw',
      height: '80vh',
      theme: 'dark',
      locale: {
        strings: {
          dropPasteFiles: "Drop files here"
        }
      }
    })
    .use(XHRUpload, {
      limit: 1,
      endpoint: url || '/upload', // path to the upload endpoint
      timeout: 0,
    });
  uppy.on('complete', (result) => {
    if (result.failed.length == 0 && redirect_url) {
      window.location = redirect_url;
    }
  })

  uppy.on('file-added', (currentFile) => {
    if (currentFile.name == 'Default.dcm' || currentFile.name.toLowerCase() == 'dicomdir') {
      uppy.info(`Please choose valid dicom file.`, 'error', 2000);
      uppy.removeFile(currentFile.id);
      return;
    }
    const result = async function (currentFile) {
      const result = await handleInput(currentFile.data);
      return result;
    }

    result(currentFile).then(v => {
      console.log(v);
      if (v == 'false') {
        uppy.info(`Please choose valid dicom file.`, 'error', 2000);
        uppy.removeFile(currentFile.id);
      }
    });
  })

  // uppy.on('upload-success', (file, response) => {
  //   // retrieve uploaded file data
  //   const uploadedFileData = response.body;
  //   document.getElementsByClassName('file-upload')[0].innerHTML = uploadedFileData.data;
  //   // set hidden field value to the uploaded file data so that it's submitted
  //   // with the form as the attachment
  // });
}


function handleInput(file) {
  return new Promise(resolve => {
    var reader = new FileReader();
    reader.readAsArrayBuffer(file);

    //Fired after sucessful file read, Please read documenation for FileReader
    reader.onload = function (evt) {
      if (evt.target.readyState === FileReader.DONE) {

        var array = new Uint8Array(evt.target.result);
        const arrayBuffer = evt.target.result;

        const dataView = new DataView(evt.target.result);

        var s = "";
        var start = 128, end = 132;
        for (var i = start; i < end; ++i) {
          s += String.fromCharCode(array[i]);
        }
        try {
          // Parse the DICOM file using dicom-parser
          const dataSet = dicomParser.parseDicom(array);
          console.log(dataSet);
          // Check if the dataset contains pixel data
          if (dataSet.elements.x7fe00010) {
            const pixelData = dataSet.elements.x7fe00010;
            if (pixelData) {
              // Pass the image data to the callback
              resolve("true");
              return;
            }
          }
        } catch (error) {
          resolve("false");
        }

        console.log("DICOM file does not contain image data.");
        resolve("false");

      }
    };
  });
}


export default fileUpload;
